.main-container {
    padding: 0px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .source-form-wrapper {
        width: 600px;
    }
}

.form-label {
    color: #1F2747;
    font-weight: 500;

    svg {
        color: #4B5168;
    }
}

.form-control {
    height: 36px;
    padding: 8px 16px;
    color: #1F2747;
    background: #FFFFFF;
    border: 1px solid #C6C8CE;
    box-shadow: inset 0px 2px 4px rgb(31 39 71 / 10%);
    border-radius: 8px;
    transition: none;

    &:hover {
        border: 1px solid #4B5168;
    }

    &:focus {
        border: 1px solid #6C89E8;
        outline: #FFFFFF;
        box-shadow: none;
    }
}

.form-check {
    display: flex;
    margin-bottom: 4px !important;

    .form-check-label {
        align-self: center;
        margin-left: 8px;
    }

}

.text-heading {
    font-weight: 600;
    height: 17px;
    line-height: 17px;
    color: #1F2747;
    margin-bottom: 4px;
}

.heading1 {
    font-weight: 500;
    height: 17px;
    line-height: 17px;
    color: #1F2747;
    margin-bottom: 8px;
}

.heading2 {
    color: #4B5168;
    line-height: 18px;
}

.button-delete {
    background: #D13E2C;
    border-radius: 8px;
    font-weight: 600;
    color: #FFFFFF;
    outline: none;
    border: none;
    height: 36px;
    padding: 0px 16px !important;

    &:hover {
        background: #DA6556;
    }
}

.button-primary {
    background: #234FDC;
    border-radius: 8px;
    font-weight: 600;
    color: #FFFFFF;
    outline: none;
    border: none;
    height: 36px;
    padding: 0px 16px !important;

    &:hover {
        background: #476CE2;
    }
}

.inactiveButton {
    opacity: 0.5;
}

.button-secondary {
    background: #F3F4F5;
    border-radius: 8px;
    color: #1F2747;
    border: 1px solid #C6C8CE;
    height: 36px;
    padding: 0px 16px;
    font-weight: 600;

    &:hover {
        background: #EAEBEE;
    }
}

.hero-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #1F2747;
    margin-bottom: 16px;
}

// source form

.source-creation-wrapper {
    width: 600px;
    padding: 36px;
    margin: 0 auto;

    >div:not(:last-child) {
        margin-bottom: 36px;
    }

    .hero-wrapper {
        .tab-button {
            font-weight: 600;
            padding: 8px 16px;
            background: #EDF0FC;
            border-radius: 8px;
            border: none;
            margin-top: 24px;
            color: #234FDC;
        }
    }

    .hero-text-wrapper {
        display: flex;
        flex-direction: column;

        .hero-text {
            div:first-child {
                color: #1F2747;
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
            }

            span {
                color: #4B5168;

            }
        }
    }

    .sourceIconsContainer {
        display: flex;
        flex-direction: column;

        .content {
            display: flex;

            div {
                &:hover {
                    border: 1px solid #4B5168;
                }

                border: 1px solid #C6C8CE;
                box-shadow: 0px 2px 2px rgba(31, 39, 71, 0.1);
                border-radius: 8px;
                height: auto;
                cursor: pointer;

                img {
                    display: block;
                    width: 36px;
                    height: auto;
                    padding: 4px;
                }
            }

            >div:not(:last-child) {
                margin-right: 8px;
            }
        }

        .icon-select-class {
            background: #EDF0FC;
            border: 1px solid #234FDC !important;
        }

    }

    .source-form-wrapper {
        position: relative;
        border-radius: 8px;

        >div:not(:last-child) {
            margin-bottom: 16px;
        }

        .checkbox-text-style {
            display: flex;
            flex-direction: column;

            >span {
                margin-left: 20px;
            }
        }

        .source-help {
            position: absolute;
            top: 137px;
            right: 5px;

            label {
                a {
                    font-weight: 400;
                    color: #476CE2;
                }
            }
        }
    }

    .source-form {
        margin-bottom: 16px;

        >div {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0px;
            }
        }

    }

    .button-wrapper {
        display: flex;
        flex-direction: row-reverse;

        >button:not(:last-child) {
            margin-left: 8px;
        }
    }

}

.css-1okebmr-indicatorSeparator {
    display: none;
}

.input-wrapper {
    .instruction {
        min-height: 60px;
        height: auto;
        overflow-y: auto;
    }
}

.file-dir-select {
    height: 200px;
    padding: 8px 16px;
    color: #1F2747;
    background: #FFFFFF;
    border: 1px solid #C6C8CE;
    box-shadow: inset 0px 2px 4px rgb(31 39 71 / 10%);
    border-radius: 8px;
    overflow-y: auto;

    >div {
        padding: 4px 0px;
    }

    &:hover {
        border: 1px solid #4B5168;
    }

    .selection-item {
        margin-bottom: 8px;
    }
}

input[type=file]::file-selector-button {
    background: #F3F4F5;
    border-radius: 8px;
    color: #1F2747;
    border: 1px solid #C6C8CE;
    height: 36px;
    padding: 0px 16px;
    font-weight: 600;

    &:hover {
        background: #EAEBEE;
        transition: none;
    }
}

.handle-file-buttons {
    display: flex;
    margin-top: 8px;

    >*:not(:last-child) {
        margin-right: 8px;
    }
}

.button-secondary.disabled {
    background-color: #ccc;
    color: #888;
    cursor: not-allowed;
}

// Modal 

// .modal-content {
//     box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14) !important;
//     border-radius: 8px !important;
//     border: 1px solid #C6C8CE !important;
//     padding: 16px;
//     background-clip: unset !important;
//     margin: 0 auto;

//     .modal-header-title {
//         margin-bottom: 24px;

//         .modal-header {
//             border-bottom: none;
//             padding: 0px;
//             margin-bottom: 8px;
//             color: #1F2747;
//             font-size: 18px;
//             padding: 0px;

//             .modal-title {
//                 display: flex;
//                 flex-direction: column;
//                 font-weight: 600;
//             }

//             .modal-close-btn {
//                 height: 36px;
//                 width: 36px;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 cursor: pointer;

//                 &:hover {
//                     border-radius: 8px;
//                     background: #F3F4F5;
//                 }

//                 .custom-close-btn {
//                     height: 16px;
//                     width: auto;
//                     padding: 11px 15px;
//                 }
//             }
//         }
//     }

//     .modal-header-description {
//         color: #1F2747;
//         font-weight: 400;
//     }

//     .modal-body {
//         padding: 0px !important;
//         color: #4B5168;
//         margin-bottom: 24px;

//         .form-check {
//             color: #1F2747;
//         }

//         .input-wrapper:not(:last-child) {
//             margin-bottom: 16px;
//         }
//     }

//     .modal-footer {
//         padding: 0px !important;
//         border-top: none !important;
//     }

//     .modal-header>h5 {
//         display: flex;
//         color: #1F2747;
//         font-weight: 600;
//         font-size: 18px;
//         line-height: 22px;
//     }
// }

// .modal-form-check {
//     color: #1F2747;
// }

@-moz-document url-prefix() {
    .source-help {
        position: absolute !important;
        top: 160px !important;
        right: 5px !important;
    }
}