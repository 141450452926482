.source-form-wrapper {


    .source-form {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 16px;
        background: #FFFFFF;
        border: 1px solid #C6C8CE;
        box-shadow: 0px 2px 2px rgba(31, 39, 71, 0.1);
        border-radius: 8px;

    }

    .videoConferencingContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .content {
            display: flex;
            flex-direction: row;
            gap: 8px;

            .disabled {
                opacity: 0.5;
                border: 1px solid #C6C8CE !important;
                background-color: #FFFFFF !important;
                cursor: default !important;
            }

            >.videoIconWrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 16px;
                width: 136px;
                height: 130px;
                background: #EDF0FC;
                border: 1px solid #234FDC;
                box-shadow: 0px 2px 2px rgba(31, 39, 71, 0.1);
                border-radius: 8px;
                cursor: pointer;

                img {
                    width: 48px;
                    height: 48px;
                    border-radius: 8px;
                }

                span {
                    color: #1F2747;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }
}

.label-with-counter {
    display: flex;
    justify-content: space-between; /* Adjust the spacing between the label and counter */
    align-items: center; /* Vertically center the items */
    width: 100%; /* Use the full width of the parent */
    // margin-bottom: 10px; /* Optional: Add some space between the label and the input */
}

.heading1, .label-with-counter > div {
    margin-right: 10px; /* Spacing between label and counter */
}
